tr.custom-row.dx-row.dx-data-row.dx-row-lines a {
    color: rgba(0,0,0,.87);
    text-decoration: none;
}

tr.custom-row.dx-row.dx-data-row.dx-row-lines.dx-row-focused a {
    color: white !important;
    text-decoration: none !important;
}

