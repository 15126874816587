.employee-hours-accordion .dx-accordion-item-body {
    padding: 0px !important
}

.employee-hours-accordion .dx-accordion .dx-accordion-item-opened .dx-accordion-item-title {
    height: 40px !important;
} 

.employee-hours-accordion .dx-accordion-item-title {
    padding-left: 10px !important;
    height: 40px !important;
}

.employee-hours-accordion .dx-accordion-item {
    box-shadow: 0 0px 0px 0px rgba(0,0,0,.2),0 0px 0px 0 rgba(0,0,0,.14),0 0px 5px 0 rgba(0,0,0,.12) !important;
}

.employee-hours-accordion .dx-accordion {
    margin: 0px !important;
}

.employee-hours-accordion .dx-accordion-item-title::before {
    top: 8px !important;
}

.employee-hours-content-block {
    margin-bottom: 10px !important;
}

.employee-hours-grid .dx-datagrid-table .dx-row > td {    
    padding-top: 3px !important;
    padding-bottom: 3px !important;    
    padding-right: 6px !important;
    padding-left: 6px !important;
    font-size: 14px;
    line-height: 18px;    
}

.employee-hours-grid .dx-datagrid-table .dx-header-row > td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;    
}