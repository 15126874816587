.employee-group {
    background-color: rgba(253, 166, 59, 0.2);
    border: 1px solid #EE8100;
    margin: 0px;
    padding: 5px 10px 2px 10px;
}

.employee-group .dx-field-label {
    width: auto;
    padding-right: 0px;
    font-size: 14px;
}

.employee-group .dx-field-value-static {
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 3px;
    padding-left: 10px;
}

.employee-group .dx-fieldset-header {
    margin: 0 0 6px;
}

.employee-notes-textarea .dx-field-value-static {
    width: 95%;
}

.employee-notes-textarea .dx-field-label {
    width: 110px !important;
    min-width: 110px !important;
}
