.employee-year {
    font-size: 18px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.employee-details-accordion .dx-accordion-item-body {
    padding: 0px !important
}

.employee-details-accordion .dx-accordion-item-title .dx-accordion-item-title-caption {
    left: 10px !important;
}

.employee-details-accordion .dx-accordion .dx-accordion-item-opened .dx-accordion-item-title {
    height: 40px !important;
} 

.employee-details-accordion .dx-accordion-item-title {
    padding-left: 10px !important;
    height: 40px !important;
}

.employee-details-accordion .dx-accordion-item-title::before {
    top: 8px !important;
}

.employee-details-accordion .dx-accordion-item {
    box-shadow: 0 0px 0px 0px rgba(0,0,0,.2),0 0px 0px 0 rgba(0,0,0,.14),0 0px 5px 0 rgba(0,0,0,.12) !important;
}

.employee-details-accordion .dx-accordion {
    margin: 0px !important;
}

.employee-details-accordion {
    margin-bottom: 10px !important;
}

.employee-toolbar .dx-toolbar .dx-toolbar-items-container {
    height: 50px;
}

.employee-header-sticky-set {
    position: fixed !important;
    top: 0 !important;    
    z-index: 99 !important;
}

.employee-header-sticky-unset {
    position: static !important;
}

#employee-header-sticky {
    background-color: white !important;
}

#employee-header-fixed {
    display: block;
}