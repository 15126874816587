.variation-month-content-block .dx-datagrid-table .dx-row-lines > td {
    /*fix per le celle verdi 29/12/2020*/
    /*background-color: #f7f7f7 !important;*/
    border-right: 1px solid #e0e0e0 !important;
}

.variation-month-content-block .dx-datagrid-table .dx-row-lines .dx-command-edit,
.variation-month-content-block .dx-datagrid-table .dx-row-lines .dx-command-adaptive {
    background-color: white !important;
}

.variation-month-content-block .dx-datagrid-table .dx-row-lines .dx-cell-modified {
    background-color: rgba(139,195,74,.32) !important;
}

.variation-month-notes-textarea .dx-field-label {
    width: 80px !important;
    min-width: 80px !important;
}
.variation-month-notes-textarea .dx-field-value-static {
    width: 100% !important;
    padding-right: 0px !important;    
    padding-left: 0px !important;    
    padding-top: 5px !important;
    padding-bottom: 15px !important;
}

.variation-month-content-block .dx-datagrid-rowsview.dx-empty {
    height: 0px !important;
}

.variation-month-content-block .dx-datagrid-table .dx-row > td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;    
    padding-right: 4px !important;
    padding-left: 4px !important;
    font-size: 13px;
    line-height: 18px;
}

.variation-month-content-block .dx-datagrid-table .dx-header-row > td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;    
}

.variation-month-content-block .dx-datagrid .dx-editor-cell .dx-texteditor .dx-texteditor-input {
    height: 24px !important;
    font-size: 13px;
    line-height: 18px;
}

.variation-month-content-block .dx-datagrid-header-panel {
    padding: 0 14px 0 10px;
}

.variation-month-content-block .dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-command-edit.dx-command-edit-with-icons {
    width: 25px !important;
    min-width: 25px !important;
}
//<<Assindatcolf overwrite theme row padding only for variations grid

.variation-month-content-block-grid-toolbar-title {
    font-size: 15px;
}

//icona di cancellazione note
.variation-month-notes-textarea .dx-show-clear-button .dx-icon-clear {
    width: 18px !important;
    height: 18px !important;
    margin-top: 5px !important;
}

.variation-month-content-block {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.variation-month-content-container {
    position: relative;
}

.variation-month-content-block-left {
    /*float: left;
    width: 100px;
    min-width: 100px;*/
    width: 55px;
    position: absolute;
    top: 0;
    left: 0;
}

.variation-month-content-block-right {    
    /*margin-left: 100px;
    width: 1200px;*/
    margin-left: 55px;
}