.tramsformation-form-container {
    margin-top: 10px;
 }

 .tramsformation-form-textarea {
    margin-top: 5px !important;
 }

 .tramsformation-form-textarea .dx-field-value-static {
    width: 100%;
    padding: 0px !important;
}

#tramsformation-archived-grid table.dx-datagrid-table.dx-datagrid-table-fixed tbody tr.dx-row.dx-freespace-row {
   display: block;
   height: 0px !important;
   min-height: 0px !important;
   max-height: 0px !important;
}

.tramsformation-form-container .dx-textarea {
   border: 1px solid #d9d9d9;
   padding: 0px 10px 0px 10px;
}