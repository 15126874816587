.popup-employers-merge-confirm-title {
    margin: 0px;
    font-weight: normal;
    font-size: 20px;
}

.popup-employers-merge-confirm-employer-tohold-data {
    background-color: rgba(253, 166, 59, 0.2);
    border: 1px solid #EE8100;
    margin: 0px;
    padding: 5px 10px 2px 10px;
}

.popup-employers-merge-confirm-employer-tohold-data .dx-field-label {
    width: 150px;
    padding-right: 0px;
    font-size: 14px;
}

.popup-employers-merge-confirm-employer-tohold-data .dx-field-value-static {
    padding-top: 3px;
    padding-right: 10px;
    padding-bottom: 3px;
    padding-left: 10px;
}

.popup-employers-merge-confirm-employer-tohold-data .dx-fieldset-header {
    margin: 0 0 6px;
}