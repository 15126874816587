.app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 20px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  //padding: 20px;
  padding: 10px;

  .screen-large & {
    //padding: 40px;
    padding: 20px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

//>>Assindatcolf overwrite theme row padding only for variations grid
.dx-row {
  height: 27px !important;   
  min-height: 27px !important;
  max-height: 27px !important;
}

//>>Assindatcolf overwrite theme row padding
.dx-datagrid .dx-row > td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 14px;
  line-height: 20px;
  padding-right: 8px !important;
  padding-left: 8px !important;
  
}

//>>Assindatcolf overwrite theme header row padding
.dx-datagrid .dx-header-row > td {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  font-size: 14px;
  line-height: 20px;
}

//>>Assindatcolf overwrite theme added cell (green)
.dx-datagrid .dx-row-inserted .dx-highlight-outline {
  padding: 0px !important;
}

//>>Assindatcolf overwrite theme filter row 
.dx-datagrid .dx-datagrid-filter-row .dx-editor-cell .dx-texteditor .dx-texteditor-input {
  height: 18px !important;
  line-height: 18px !important;
}
.dx-datagrid .dx-editor-with-menu {
  height: 18px !important;
}
.dx-datagrid .dx-editor-with-menu .dx-menu-item-content .dx-icon.dx-icon-filter-operation-default {
  margin-top: -30px !important;
}
.dx-datagrid .dx-menu .dx-menu-item .dx-menu-item-content .dx-icon {
  margin-top: -30px !important;
}

//>>Assindatcolf toolbar height
.dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container {
  height: 36px !important;
}

//Assindatcolf size of revert button in grid
.dx-datagrid-revert-tooltip .dx-revert-button {
  height: 23px !important;
}
.dx-datagrid-revert-tooltip .dx-revert-button .dx-icon {
  width: 20px !important;
  height: 20px !important;
}

//>>Assindatcolf no data grid height
.dx-datagrid-rowsview.dx-empty {
  height: 50px !important;
}

//>>Assindatcolf button default in login form
.login-form .dx-button.dx-button-default {
  background-color: #EE8100 !important;
}

//>>Assindatcolf button default
.ac-button-default{ background-color: #EE8100 !important; }

//>>Assindatcolf page title
.ac-page-title { font-size: 20px; }

//>>Assindatcolf text area
.ac-textarea {
  border: 1px solid #d9d9d9;
  padding: 3px 5px 3px 5px;   
  background-color: white !important;
}

.ac-textarea .dx-texteditor-input {  
  background-color: transparent !important;  
  font-size: 13px;
  line-height: 20px;
}

.ac-textarea .dx-texteditor.dx-editor-underlined .dx-texteditor-input { padding: 2px !important; }

.ac-float-letf { float: left; }

.ac-float-right { float: right; }

.ac-clear-both{ clear: both; }

.ac-accordion-title{ font-size: 15px; }

.ac-accordion-title-left{ float: left; }

.ac-accordion-title-right{
  float: right;
  margin-right: 35px;
  font-size: 14px;
}

.ac-accordion-title-right-small{
  float: right;
  margin-right: 35px;
  font-size: 14px;
  line-height: 0.6;
  margin-top: -5px;
  padding-top: 0px;
}

.ac-centered {
  //height: 100vh; /* Magic here */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ac-field-label {
  color: rgba(117,117,117,.87);
  font-size: 12px;
}

.ac-margin-top-05 { margin-top: 5px !important; }

.ac-margin-top-10 { margin-top: 10px !important; }

.ac-margin-top-20 { margin-top: 20px !important; }

.ac-margin-top-30 { margin-top: 30px !important; }

.ac-margin-left-10 { margin-left: 10px !important; }

.ac-card-title{
  font-size: 16px;
  padding-bottom: 5px;
}

.ac-fieldset-header{
  font-size: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.ac-container-new-item {
  background-color: rgb(0, 119, 255);
  color: white;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
  font-size: 16px; 
  font-weight: 500;
  text-align: center;
}

.ac-container-opened-item {
  background-color: rgb(0, 184, 0);
  color: white;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
  font-size: 16px; 
  font-weight: 500;
  text-align: center;
}

.ac-container-archived-item {
  background-color: grey;
  color: white;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
  font-size: 16px; 
  font-weight: 500;
  text-align: center;
}

.ac-container-closed-item {
  background-color: red;
  color: white;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
  font-size: 16px; 
  font-weight: 500;
  text-align: center;
}

.ac-container-suspended-item {
  background-color: rgb(255, 145, 0);
  color: white;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
  font-size: 16px; 
  font-weight: 500;
  text-align: center;
}

.ac-container-closedIndefinitely-item {
  background-color: rgb(255, 0, 242);
  color: white;
  border-radius: 5px;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 120px;
  font-size: 16px; 
  font-weight: 500;
  text-align: center;
}

.ac-grid-cell-closed-item {
  padding: 4px;
  border-radius: 4px;
  background-color: red;
  color: white;
  border: 1px solid white;
}

.ac-grid-cell-opened-item {
  padding: 4px;
  border-radius: 4px;
  background-color: rgb(0, 184, 0);
  color: white;
  border: 1px solid white
}

.ac-grid-cell-suspended-item {
  padding: 4px;
  border-radius: 4px;
  background-color: rgb(255, 145, 0);
  color: white;
  border: 1px solid white;
  text-align: center;  
}

.ac-grid-cell-closedIndefinitely-item {
  padding: 4px;
  border-radius: 4px;
  background-color: rgb(255, 0, 242);
  color: white;
  border: 1px solid white
}

.dx-form-group-caption {
  font-size: 16px !important;
}

.dx-popup-bottom.dx-toolbar {
  padding: 0 24px 0px 24px;
}