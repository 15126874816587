#form {
    max-width: 600px;
    margin: auto;
}

.button {
    position: absolute;
    right:    40px;
    bottom:   40px;   
}

.checkbox {
    margin-left: 20px;
    margin-top: 30px;
}

.select {
    margin-left: 20px;
    max-width: 100px;
}

.fileuploader-container{
    margin: 10px 0px 0 10px;
}

#form h3 {
    margin-left: 20px;
    font-weight: normal;
    font-size: 22px;
}