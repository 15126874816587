//>>Assindatcolf toolbar height
#stakeholders-grid .dx-datagrid-header-panel .dx-toolbar .dx-toolbar-items-container {
   height: 60px !important;
}

//>>Assindatcolf width for search panel (only for takeholders-grid)
#stakeholders-grid .dx-datagrid-search-panel {   
   margin: 0px !important;
} 

.report-filter-container {
   background-color: white;
   margin-bottom: 10px;
   padding: 10px;
}

.report-filter-container .report-filter-title {
   font-weight: 200;
   font-size: 16px;
   text-align: left;
   margin-bottom: 10px;
}

.report-filter-buttons-left {
   float: left;
}
.report-filter-buttons-right {
   float: right;
}

.report-filter-buttons {
   margin-top: 10px;
}